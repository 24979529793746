<template>
  <v-container fluid>
    <v-row
      ><v-col>
        <p class="ma-n1">
          <router-link to="/"
            ><v-img max-width="100px" src="3sigma.png"></v-img
          ></router-link>
        </p>
        <p class="text-h5">3 Sigma Surgeon</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="left">
        <p ref="intro" class="text-h6 mt-6">About Us</p>
        <p>A note from one surgeon to another.</p>
        <p>
          We all like to think that we are great surgeons. Being ahead of the
          curve early in our life can make it hard to reconcile that at the top,
          there's another curve and the majority of us are in the middle of this
          curve. It is easy to dismiss our bad streaks and glorify our good
          runs. It is a difficult learning process for junior surgeons to
          dissociate their ego from their outcomes. Being honest when evaluating
          our own performance is a good start. Knowing when it is no longer a
          chance occurrence is critical in helping us reflect and learn. If we
          are genuinely doing better than published data, it is a prompt to
          consider what we are doing well and share. Likewise, when we are not
          performing well against a benchmark, we should critically evaluate how
          we can improve.
        </p>
        <p>
          As with most of my inventions, this tool was born out of frustration.
          My frustration of not being able to quantify how I am performing when
          compared to published rates. I needed numbers to help ground me when I
          am doing well and reassure me when I am experiencing a bad run. These
          frustrations, combined with my fascination with probabilities, led to
          the creation of this tool.
        </p>
        <p>
          I hope you enjoy using this tool as much as I enjoyed learning and
          creating this tool.
        </p>
        <p><i>Weng</i></p>
        <p class="text-h6 mt-8">Acknowledgments</p>
        <p>
          A special thanks to Mr. Ian Teoh for making this website a reality,
          and huge thanks to all my colleagues who helped along this journey:
        </p>
        <p class="text-h6">Contributors:</p>
        <div v-for="n in names_contributors" v-bind:key="n">
          <p class="mb-n1">{{ n }}</p>
        </div>
        <p></p>
        <p class="text-h6">Thank you to:</p>
        <div v-for="n in names_feedback" v-bind:key="n">
          <p class="mb-n1">{{ n }}</p>
        </div>
      </v-col></v-row
    >
  </v-container>
</template>


<script>
export default {
  data: function () {
    return {
      names_feedback: [],
      names_contributors: [],
    };
  },
  mounted: function () {
    var names_feedback = `
Dr. Hosam Abdalla
Dr. Nick Andrew
Dr. Jas Aujla
Dr. Stephen Bacci
Dr. Bobak Bahrami
Dr. Gaurav Bhardwaj
Dr. Nicky Brennan
Ms. Stephanie Callisto
Dr. Luke Chehade
Dr. Mark Chehade
Dr. Tim Cheong
Prof. Jamie Craig
Dr. James Dowling
Dr. Andreas Ebneter
Dr. Lachie Farmer
Dr. Anna Galanopoulos
A/Prof. Jolly Gilhotra
Dr. Nicola Glasson
A/Prof. Michael Goggin
Dr. Tze Goh
Dr. Tim Greenwell
Dr. Chelsea Guymer
Dr. Mark Hassall
Dr. Josh Hayes
A/Prof. Alex Hunyor
Dr. Valerie Juniat
Prof. Hirohiko Kakizaki
Dr. Arthur Karagiannis
Ms. Amber Kraczkowska
Prof. Misiek Kraczkowski
Dr. Ben LaHood
Dr. Stewart Lake
A/Prof. John Landers
Dr. Shane Lee
Dr. Matthew Little
Dr. Susie Luu
Dr. Carmelo Macri
Dr. Simon Madge
Dr. Soo Ng
Dr. Adnan Pirbhai
Dr. Grant Raymond
Dr. Mel Shields
Dr. David Sia
Dr. Paul Sia
Dr. Andy Simpson
Dr. James Slattery
Dr. Michelle Sun
Dr. Ian Tan
Dr. Deepa Taranath
Dr. Leong Tiong
Dr. Chen Tu
Dr. Michel Von
Dr. Robbie Walker
Dr. Eranda Wannigama
Dr. Wilson Wong
Dr. Megan Wood
Dr. Charles Yong
Dr. YewSen Yuen
`;
    this.names_feedback = names_feedback.split("\n");

    var names_contributors = `
Prof. Robert Casson
Dr. WanYin Lim
Prof. Dinesh Selva
Dr. IngSoo Tiong
`;
    this.names_contributors = names_contributors.split("\n");
  },
};
</script>

<style>
</style>